<template>
  <b-card
    v-if="yandexMarketFbs.length || yandexMarketDbs.length || merlionFreshYmfbs.length || merlionYmdbs.length"
    class="offer-integrations"
    no-body
  >
    <h4 class="mt-2">
      Интеграции
    </h4>

    <b-table-simple
      :striped="true"
      :bordered="false"
      :borderless="true"
      :outlined="false"
      :small="false"
      :hover="false"
      :dark="false"
      :fixed="false"
      :responsive="true"
      class="mt-1"
    >
      <b-tbody>
        <b-tr
          v-for="item in yandexMarketFbs"
          :key="'ymfbs' + item.id"
        >
          <b-th>
            {{ get(integrations.find(i => i.id === item.id && i.type === 'yandex_market_fbs'), 'name') }}
            <div class="small text-monospace">
              {{ get(integrations.find(i => i.id === item.id && i.type === 'yandex_market_fbs'), 'humanType') }}
            </div>
          </b-th>
          <b-td>
            <b-badge
              v-if="item.status"
              variant="success"
              pill
            >
              {{ item.status }}
            </b-badge>
            <b-alert
              v-if="item.notes"
              variant="warning"
              show
              class="mb-0"
            >
              <div
                class="alert-body"
              >
                <span>{{ item.notes }}</span>
              </div>
            </b-alert>
          </b-td>
          <b-td>

            <b-badge
              v-if="mode === 'view'"
              class="offer-card__label"
              :variant="item.enabled ?'success' : 'secondary'"
              size="sm"
              pill
            >
              <template v-if="item.enabled">
                Вкл
              </template>
              <template v-else>
                Выкл
              </template>
            </b-badge>

            <b-form-checkbox
              v-else
              v-model="item.enabled"
              switch
              :value="true"
              :unchecked-value="false"
            >
              <template v-if="item.enabled">
                Вкл
              </template>
              <template v-else>
                Выкл
              </template>
            </b-form-checkbox>
          </b-td>
        </b-tr>
        <b-tr
          v-for="item in yandexMarketDbs"
          :key="'ymdbs' + item.id"
        >
          <b-th>
            {{ get(integrations.find(i => i.id === item.id && i.type === 'yandex_market_dbs'), 'name') }}
            <div class="small text-monospace">
              {{ get(integrations.find(i => i.id === item.id && i.type === 'yandex_market_dbs'), 'humanType') }}
            </div>
          </b-th>
          <b-td>
            <b-badge
              v-if="item.status"
              variant="success"
              pill
            >
              {{ item.status }}
            </b-badge>
            <b-alert
              v-if="item.notes"
              variant="warning"
              show
              class="mb-0"
            >
              <div
                class="alert-body"
              >
                <span>{{ item.notes }}</span>
              </div>
            </b-alert>
          </b-td>
          <b-td>

            <b-badge
              v-if="mode === 'view'"
              class="offer-card__label"
              :variant="item.enabled ?'success' : 'secondary'"
              size="sm"
              pill
            >
              <template v-if="item.enabled">
                Вкл
              </template>
              <template v-else>
                Выкл
              </template>
            </b-badge>

            <b-form-checkbox
              v-else
              v-model="item.enabled"
              switch
              :value="true"
              :unchecked-value="false"
            >
              <template v-if="item.enabled">
                Вкл
              </template>
              <template v-else>
                Выкл
              </template>
            </b-form-checkbox>
          </b-td>
        </b-tr>
        <b-tr
          v-for="item in merlionFreshYmfbs"
          :key="'mfy' + item.id"
        >
          <b-th>
            {{ get(integrations.find(i => i.id === item.id && i.type === 'merlion_fresh_ymfbs'), 'name') }}
            <div class="small text-monospace">
              {{ get(integrations.find(i => i.id === item.id && i.type === 'merlion_fresh_ymfbs'), 'humanType') }}
            </div>
          </b-th>
          <b-td>
            <b-badge
              v-if="item.status"
              variant="success"
              pill
            >
              {{ item.status }}
            </b-badge>
            <b-alert
              v-if="item.notes"
              variant="warning"
              show
              class="mb-0"
            >
              <div
                class="alert-body"
              >
                <span>{{ item.notes }}</span>
              </div>
            </b-alert>
          </b-td>
          <b-td>

            <b-badge
              v-if="mode === 'view'"
              class="offer-card__label"
              :variant="item.enabled ?'success' : 'secondary'"
              size="sm"
              pill
            >
              <template v-if="item.enabled">
                Вкл
              </template>
              <template v-else>
                Выкл
              </template>
            </b-badge>

            <b-form-checkbox
              v-else
              v-model="item.enabled"
              switch
              :value="true"
              :unchecked-value="false"
            >
              <template v-if="item.enabled">
                Вкл
              </template>
              <template v-else>
                Выкл
              </template>
            </b-form-checkbox>
          </b-td>
        </b-tr>
        <b-tr
          v-for="item in merlionYmdbs"
          :key="'mydbs' + item.id"
        >
          <b-th>
            {{ get(integrations.find(i => i.id === item.id && i.type === 'merlion_ymdbs'), 'name') }}
            <div class="small text-monospace">
              {{ get(integrations.find(i => i.id === item.id && i.type === 'merlion_ymdbs'), 'humanType') }}
            </div>
          </b-th>
          <b-td>
            <b-badge
              v-if="item.status"
              variant="success"
              pill
            >
              {{ item.status }}
            </b-badge>
            <b-alert
              v-if="item.notes"
              variant="warning"
              show
              class="mb-0"
            >
              <div
                class="alert-body"
              >
                <span>{{ item.notes }}</span>
              </div>
            </b-alert>
          </b-td>
          <b-td>

            <b-badge
              v-if="mode === 'view'"
              class="offer-card__label"
              :variant="item.enabled ?'success' : 'secondary'"
              size="sm"
              pill
            >
              <template v-if="item.enabled">
                Вкл
              </template>
              <template v-else>
                Выкл
              </template>
            </b-badge>

            <b-form-checkbox
              v-else
              v-model="item.enabled"
              switch
              :value="true"
              :unchecked-value="false"
            >
              <template v-if="item.enabled">
                Вкл
              </template>
              <template v-else>
                Выкл
              </template>
            </b-form-checkbox>
          </b-td>
        </b-tr>
      </b-tbody>

    </b-table-simple>
  </b-card>
</template>

<script>
import {
  onMounted,
} from 'vue'
import {
  BCard, BTableSimple, BTbody, BTr, BTh, BTd, BBadge, BAlert, BFormCheckbox,
} from 'bootstrap-vue'
import get from 'lodash/get'
import useIntegrations from '@/composables/useIntegrations'

export default {
  name: 'OfferIntegrations',
  components: {
    BCard, BTableSimple, BTbody, BTr, BTh, BTd, BBadge, BAlert, BFormCheckbox,
  },
  props: {
    yandexMarketFbs: {
      type: Array,
      default: () => ([]),
    },
    yandexMarketDbs: {
      type: Array,
      default: () => ([]),
    },
    merlionFreshYmfbs: {
      type: Array,
      default: () => ([]),
    },
    merlionYmdbs: {
      type: Array,
      default: () => ([]),
    },
    mode: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { getItems: getIntegrations, items: integrations } = useIntegrations()

    onMounted(async () => {
      await getIntegrations()
    })

    return {
      integrations,
      get,
    }
  },
}
</script>

<style scoped lang="scss">
.offer-integrations{

}
</style>
