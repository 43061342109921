<template>
  <b-card title="Товар">
    <b-card
      title="Основное"
      class="mb-0"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group label="Название">
            <b-form-input
              :value="product.name"
              type="text"
              readonly
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group label="Категория">
            <b-form-input
              :value="category.name"
              type="text"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group label="Бренд">
            <b-form-input
              :value="brand.name"
              type="text"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group label="Cтатус">
            <b-form-input
              :value="statuses[product.status]"
              type="text"
              readonly
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group label="Модель">
            <b-form-input
              :value="product.model"
              type="text"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group label="EAN">
            <b-form-input
              :value="product.ean"
              type="text"
              readonly
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Описание">
            <b-form-textarea
              :value="product.description"
              readonly
              no-resize
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      title="Габариты и вес товара"
      class="mb-0"
    >
      <b-row v-if="product.dimensions">
        <b-col
          v-for="dimension in ['netto', 'brutto']"
          :key="dimension"
          cols="12"
          md="6"
        >
          <b-card
            class="mb-0"
            no-body
          >
            <b-card-title>{{ dimension }}</b-card-title>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group label="Длинна, мм">
                  <b-form-input
                    :value="product.dimensions[dimension].length"
                    type="number"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group label="Ширина, мм">
                  <b-form-input
                    :value="product.dimensions[dimension].width"
                    type="text"
                    readonly
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group label="Высота, мм">
                  <b-form-input
                    :value="product.dimensions[dimension].height"
                    type="number"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group label="Вес, грамм">
                  <b-form-input
                    :value="product.dimensions[dimension].weight"
                    type="number"
                    readonly
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="mb-0">
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-card
            class="mb-0"
            no-body
          >
            <b-card-title>SEO</b-card-title>
            <b-row v-if="product.seo">
              <b-col
                cols="12"
                md="12"
              >
                <b-form-group label="Title">
                  <b-form-input
                    :value="product.seo.title"
                    readonly
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="product.seo">
              <b-col
                cols="12"
                md="12"
              >
                <b-form-group label="Description">
                  <b-form-textarea
                    :value="product.seo.description"
                    readonly
                    no-resize
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="product.seo">
              <b-col
                cols="12"
                md="12"
              >
                <b-form-group label="Keywords">
                  <b-form-textarea
                    :value="product.seo.keyword"
                    readonly
                    no-resize
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-card
            class="mb-0"
            no-body
          >
            <b-card-title>Срок службы и гарантия</b-card-title>
            <b-row>
              <b-col>
                <b-form-group label="Срок службы (в днях)">
                  <b-form-input
                    :value="product.lifespan"
                    type="number"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Гарантия производителя (в днях)">
                  <b-form-input
                    :value="product.warranty_period"
                    type="number"
                    readonly
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Комментарий">
                  <b-form-textarea
                    :value="product.lifespan_comment"
                    readonly
                    no-resize
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Комментарий">
                  <b-form-textarea
                    :value="product.warranty_period_comment"
                    readonly
                    no-resize
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      title="Характеристики"
      class="mb-0"
    >
      <b-row v-if="attributes.length">
        <div
          v-for="category in attributeCategories"
          :key="category.id"
          class="d-contents"
        >
          <b-col
            v-if="getFilteredAttributes(category.id).length"
            cols="12"
            md="4"
          >
            <b-card
              class="mb-0"
              no-body
            >
              <b-card-title>{{ category.name }}</b-card-title>
              <b-form-group
                v-for="attribute in getFilteredAttributes(category.id)"
                :key="attribute.id"
                :label="getAttributeLabel(attribute)"
              >
                <b-form-input
                  :value="product.attributes[attribute.id]"
                  readonly
                />
              </b-form-group>
            </b-card>
          </b-col>
        </div>
      </b-row>
      <h6 v-else>
        Характеристики отсутствуют
      </h6>
    </b-card>
    <b-card
      title="Дополнительно"
      class="mb-0"
    >
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group label="ТН ВЭД">
            <b-form-input
              :value="product.tn_ved"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            v-if="product.manufacturer_countries"
            label="Страна производитель"
          >
            <b-form-input
              :value="product.manufacturer_countries.join(', ')"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group label="Merlion ID">
            <b-form-input
              :value="product.merlion_id"
              readonly
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      no-body
      class="mb-0"
    >
      <b-row>
        <!--        <b-col-->
        <!--          cols="12"-->
        <!--          md="6"-->
        <!--        >-->
        <!--          <b-card-->
        <!--            title="Изображения"-->
        <!--            class="mb-0"-->
        <!--          >-->
        <!--            <b-row v-if="product.images && product.images.length">-->
        <!--              <b-col-->
        <!--                v-for="image in product.images"-->
        <!--                :key="image.id"-->
        <!--                class="mt-1"-->
        <!--              >-->
        <!--                <img-->
        <!--                  class="image mr-1"-->
        <!--                  :src="image.path"-->
        <!--                  :alt="product.name"-->
        <!--                >-->
        <!--              </b-col>-->
        <!--            </b-row>-->
        <!--            <b-row v-else>-->
        <!--              <b-col>-->
        <!--                <h6>Изображения отсутствуют</h6>-->
        <!--              </b-col>-->
        <!--            </b-row>-->
        <!--          </b-card>-->
        <!--        </b-col>-->
        <b-col
          cols="12"
          md="6"
        >
          <b-card title="Файлы">
            <div v-if="product.files && product.files.length">
              <div
                v-for="file in product.files"
                :key="file.id"
              >
                {{ file.title || file.file_name }}
              </div>
            </div>
            <h6 v-else>
              Файлы отсутствуют
            </h6>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </b-card>
</template>

<script>
import { ref } from 'vue'
import {
  BCard, BCol, BRow, BFormGroup, BFormInput, BFormTextarea, BCardTitle,
} from 'bootstrap-vue'
import { getCategory } from '@/services/main-api/catalog/products/categories'
import { getBrand } from '@/services/main-api/catalog/products/brands'
import { getAttributes } from '@/services/main-api/catalog/products/attributes'
import { getCategories as getAttributeCategories } from '@/services/main-api/catalog/products/attribute_categories'
import { getUnits } from '@/services/main-api/catalog/products/units'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BCardTitle,
  },
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const category = ref({})
    const brand = ref({})
    const attributes = ref([])
    const attributeCategories = ref([])
    const units = ref([])
    const statuses = {
      public: 'Опубликован',
      draft: 'Черновик',
      pending: 'Ожидает модерации',
      moderation: 'На модерации',
      hidden: 'Скрыт',
    }

    return {
      statuses,
      category,
      brand,
      attributes,
      attributeCategories,
      units,
    }
  },
  watch: {
    async product(product) {
      const resCategory = await getCategory({ id: product.product_category_id })
      const resBrand = await getBrand(product.product_brand_id)
      const resAttributeCategories = await getAttributeCategories({ product_category: product.product_category_id })
      const resAttributes = await getAttributes({ product_category: product.product_category_id })
      const resUnits = await getUnits()

      this.category = resCategory.data.data
      this.brand = resBrand.data.data
      this.attributes = resAttributes.data.data
      this.units = resUnits.data.data
      this.attributeCategories = [
        { id: null, name: 'Без категории' },
        ...resAttributeCategories.data.data,
      ]
    },
  },
  methods: {
    getFilteredAttributes(categoryId) {
      return [...this.attributes].filter(attribute => (
        attribute.product_attribute_category_id === categoryId
      ))
    },
    getAttributeLabel(attribute) {
      const { name, measurement_unit_id: unitId } = attribute
      const currUnit = this.units.find(unit => unit.id === unitId)
      const unitTitle = `${currUnit?.name}, ${currUnit?.code_ru}`

      return unitId ? `${name} (${unitTitle})` : name
    },
  },
}
</script>

<style scoped lang="scss">
.image {
  border: 1px solid #ccc;
  border-radius: 6px;
  object-fit: contain;
  max-width: 100px;
  max-height: 100px;
  width: 100%;
  height: 100%;
}

.d-contents {
  display: contents;
}
</style>
