import axiosIns from '@/libs/axios'

export function getAttribute(id) {
  return axiosIns.get(`/catalog/products/attributes/attributes/${id}`)
}

export function deleteAttribute(id) {
  return axiosIns.delete(`/catalog/products/attributes/attributes/${id}`)
}

export function getAttributes({
  product_category, perPage, sortedBy, orderBy, page, search, searchFields, searchJoin,
}) {
  return axiosIns.get('/catalog/products/attributes/attributes', {
    params: {
      product_category,
      perPage,
      sortedBy,
      orderBy,
      page,
      search,
      searchFields,
      searchJoin,
    },
  })
}

export function createAttribute({
  name,
  type,
  product_category_id,
  unit,
  values,
  sort_order,
  product_attribute_category_id,
  is_filter,
}) {
  return axiosIns.post('/catalog/products/attributes/attributes', {
    name,
    type,
    product_category_id,
    unit,
    values,
    sort_order,
    product_attribute_category_id,
    is_filter,
  })
}

export function updateAttribute({
  id,
  name,
  type,
  unit,
  values,
  sort_order,
  product_attribute_category_id,
  is_filter,
}) {
  return axiosIns.patch(`/catalog/products/attributes/attributes/${id}`, {
    name,
    type,
    unit,
    values,
    sort_order,
    product_attribute_category_id,
    is_filter,
  })
}

export default {
  getAttribute,
  getAttributes,
  createAttribute,
  updateAttribute,
}
