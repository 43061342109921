<template>
  <b-card
    class="warehouse-remains"
    title="Остатки"
  >
    <b-table-simple
      :striped="true"
      :bordered="false"
      :borderless="true"
      :outlined="false"
      :small="false"
      :hover="false"
      :dark="false"
      :fixed="false"
      :responsive="true"
      class="mt-1"
    >
      <b-tbody>
        <b-tr
          v-for="item in warehouseAndRemain"
          :key="item.id"
        >
          <b-th>
            {{ item.name }}
          </b-th>
          <b-td>
            <div class="text-success">
              Остаток: {{ item.product_warehouse_remain.total }}
            </div>
            <div class="text-secondary">
              Резерв: {{ item.product_warehouse_remain.reserve }}
            </div>
          </b-td>
          <b-td
            v-if="$store.getters['workingMode/checkPermission']('manage_product_offers')"
          >
            <b-button
              variant="flat-primary"
              class="btn-icon"
              size="sm"
              @click="showUpdateRemainSidebar(item)"
            >
              <feather-icon icon="Edit2Icon" />
            </b-button>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <!--    <div
      v-for="item in warehouseAndRemain"
      :key="item.id"
      class="mb-3"
    >
      <pre>{{ item.name }}</pre>
      <pre>{{ item.product_warehouse_remain || 'null' }}</pre>
      <b-button @click="showUpdateRemainSidebar(item)">
        Изменить остаток
      </b-button>
    </div>-->

    <b-sidebar
      :visible="isActiveSidebar"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @hidden="clearForm"
      @change="(val) => {isActiveSidebar = val}"
    >
      <template #default="{ hide }">
        <b-overlay
          :show="loading"
          spinner-variant="primary"
        >
          <!-- Header -->
          <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
            <h5 class="mb-0">
              Изменить остаток
            </h5>

            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
          <!-- Body -->
          <div
            v-if="selectedItem.product_warehouse_remain"
            class="p-2"
          >
            <b-row v-if="errorMessage">
              <b-col>
                <small
                  class="text-danger d-block mb-1"
                >
                  {{ errorMessage }}
                </small>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
              >
                <b-form-group
                  label="Склад"
                  label-for="warehouse"
                >
                  <b-form-input
                    id="warehouse"
                    :value="selectedItem.name"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
              >
                <b-form-group
                  label="Остаток"
                  label-for="total"
                >
                  <b-form-input
                    id="total"
                    v-model.number="selectedItem.product_warehouse_remain.total"
                    placeholder="Остаток"
                    :state="get(validation, 'name[0]') ? false : null"
                  />
                  <small
                    v-if="!!get(validation, 'name[0]')"
                    class="text-danger"
                  >
                    {{ get(validation, 'name[0]') }}
                  </small>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Form Actions -->
            <div class="d-flex mt-1">
              <b-button
                variant="primary"
                class="mr-2"
                @click="updateRemain"
              >
                Сохранить
              </b-button>
              <b-button
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Отмена
              </b-button>
            </div>
          </div>
        </b-overlay>
      </template>
    </b-sidebar>
  </b-card>
</template>

<script>
import {
  onMounted, ref, computed, getCurrentInstance,
} from 'vue'
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import {
  BCard, BButton, BSidebar, BRow, BCol, BFormGroup, BFormInput, BOverlay, BTableSimple, BTbody, BTr, BTh, BTd,
} from 'bootstrap-vue'
import {
  createProductWarehouseRemain,
  updateProductWarehouseRemain,
} from '@/services/main-api/shop/product-warehouse-remains'
import { getWarehouses } from '@/services/main-api/shop/warehouses'

export default {
  name: 'WarehouseRemains',
  components: {
    BCard,
    BButton,
    BSidebar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BOverlay,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
  },
  props: {
    offer: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const instance = getCurrentInstance().proxy
    const loading = ref(false)
    const items = ref([])
    const isActiveSidebar = ref(false)
    const selectedItem = ref({})
    const validation = ref({})
    const errorMessage = ref('')

    const getItems = async () => {
      try {
        const res = await getWarehouses({
          project: instance.$store.getters['workingMode/selected_project_id'],
          perPage: 1000,
          sortedBy: 'desc',
          orderBy: 'name',
          page: 1,
          includes: 'real_address',
        })

        items.value = res.data.data
      } catch (e) {
        items.value = []
      }
    }

    const warehouseAndRemain = computed(() => items.value.map(i => {
      // eslint-disable-next-line camelcase
      let product_warehouse_remain = {
        id: null,
        total: 0,
      }

      if (props.offer && props.offer.product_warehouse_remains) {
        const remains = props.offer.product_warehouse_remains.find(r => r.warehouse_id === i.id)
        if (remains) {
          // eslint-disable-next-line camelcase
          product_warehouse_remain = remains
        }
      }

      return {
        ...i,
        product_warehouse_remain,
      }
    }))

    const showUpdateRemainSidebar = async updateItem => {
      selectedItem.value = cloneDeep(updateItem)
      isActiveSidebar.value = true
    }

    const clearForm = () => {
      selectedItem.value = {}
    }

    const updateRemain = async () => {
      validation.value = {}
      errorMessage.value = ''
      loading.value = true
      try {
        if (selectedItem.value.product_warehouse_remain.id) {
          await updateProductWarehouseRemain({
            id: selectedItem.value.product_warehouse_remain.id,
            total: selectedItem.value.product_warehouse_remain.total,
          })
        } else {
          await createProductWarehouseRemain({
            warehouse_id: selectedItem.value.id,
            product_offer_id: props.offer.id,
            total: selectedItem.value.product_warehouse_remain.total,
          })
        }

        clearForm()
        validation.value = {}
        errorMessage.value = ''
        isActiveSidebar.value = false
        emit('update-remain')
      } catch (e) {
        validation.value = get(e.response, 'data.errors')
        errorMessage.value = get(e.response, 'data.error', '')
      } finally {
        loading.value = false
      }
    }

    onMounted(async () => {
      loading.value = true
      await getItems()
      loading.value = false
    })

    return {
      items,
      warehouseAndRemain,
      loading,
      isActiveSidebar,
      selectedItem,
      showUpdateRemainSidebar,
      clearForm,
      validation,
      errorMessage,
      updateRemain,

      get,
    }
  },
}
</script>

<style scoped>

</style>
