<template>
  <div v-if="getItemError">
    <b-alert
      variant="danger"
      show
    >
      <h4 class="alert-heading">
        Ошибка при получении данных предложения
      </h4>
      <div class="alert-body">
        Не найдено предложения с этим идентификатором. Можете перейти в
        <b-link
          class="alert-link"
          :to="{ name: 'catalog'}"
        >
          Каталог
        </b-link>
        и выбрать другое.
      </div>
    </b-alert>
  </div>
  <b-overlay
    v-else
    :show="loading"
    spinner-variant="primary"
  >
    <b-card>
      <b-row
        align-v="center"
        no-gutters
      >
        <b-col cols="auto">
          <b-avatar
            :src="get(product, 'images[0].path')"
            rounded="sm"
            :alt="product.name"
            size="75px"
            icon="ImageIcon"
            variant="light-secondary"
            class="mr-1"
          >
            <span
              v-if="!get(product, 'images[0].path')"
              class="d-flex align-items-center"
            >
              <feather-icon
                icon="ImageIcon"
                size="16"
              />
            </span>
          </b-avatar>
        </b-col>
        <b-col>
          <div class="text-secondary">
            <small>{{ product.code }}</small>
          </div>
          <div><strong>{{ product.name }}</strong></div>
        </b-col>
      </b-row>
      <b-row
        v-if="product.description"
        class="mt-1"
      >
        <b-col>
          {{ product.description }}
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <div class="">
        <b-row class="mb-1 text-right">
          <b-col>
            <b-button
              variant="primary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :disabled="addImageLoader"
              @click="updateItem"
            >
              <template v-if="!addImageLoader">
                Сохранить
              </template>
              <template v-else>
                <b-row
                  align-v="center"
                  align-h="center"
                  no-gutters
                >
                  <b-col cols="auto">
                    <b-spinner
                      small
                      class="mr-1"
                    />
                  </b-col>
                  <b-col cols="auto">
                    Loading...
                  </b-col>
                </b-row>
              </template>
            </b-button>
          </b-col>
        </b-row>

        <b-row v-if="error_message">
          <b-col>
            <small
              class="text-danger d-block mb-1"
            >
              {{ error_message }}
            </small>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Активность"
              label-for="is-active"
            >
              <b-form-checkbox
                id="is-active"
                v-model="item.is_active"
                :state="get(validation, 'is_active[0]') ? false : null"
                switch
                :value="true"
                :unchecked-value="false"
              >
                <template v-if="item.is_active">
                  Вкл
                </template>
                <template v-else>
                  Выкл
                </template>
              </b-form-checkbox>
              <small
                v-if="!!get(validation, 'is_active[0]')"
                class="text-danger"
              >
                {{ get(validation, 'is_active[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Внешний id"
              label-for="external_public_id"
              description="Идентификатор торгового предложения в системе пользователя. Можно указать только если исходное значение у торгового предложения null, в противном случае будет проигнорировано."
            >
              <b-form-input
                id="external_public_id"
                v-model.trim="item.external_public_id"
                placeholder="Введите описание"
                :state="get(validation, 'external_public_id[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'external_public_id[0]')"
                class="text-danger"
              >
                {{ get(validation, 'external_public_id[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Закупочная цена"
              label-for="purchase_price"
            >
              <b-input-group
                append="₽"
              >
                <b-form-input
                  id="purchase_price"
                  v-model.trim="item.purchase_price"
                  placeholder="Закупочная цена"
                  :state="get(validation, 'purchase_price[0]') ? false : null"
                  type="number"
                  lang="ru"
                />
              </b-input-group>

              <small
                v-if="!!get(validation, 'purchase_price[0]')"
                class="text-danger"
              >
                {{ get(validation, 'purchase_price[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Основная цена"
              label-for="price"
            >
              <b-input-group
                append="₽"
              >
                <b-form-input
                  id="price"
                  v-model.trim="item.price"
                  placeholder="Основная цена"
                  :state="get(validation, 'price[0]') ? false : null"
                  type="number"
                  lang="ru"
                />
              </b-input-group>

              <small
                v-if="!!get(validation, 'price[0]')"
                class="text-danger"
              >
                {{ get(validation, 'price[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Акционная цена"
              label-for="sale_price"
            >
              <b-input-group
                append="₽"
              >
                <b-form-input
                  id="sale_price"
                  v-model.trim="item.sale_price"
                  placeholder="Акционная цена"
                  :state="get(validation, 'sale_price[0]') ? false : null"
                  type="number"
                  lang="ru"
                />
              </b-input-group>
              <small
                v-if="!!get(validation, 'sale_price[0]')"
                class="text-danger"
              >
                {{ get(validation, 'sale_price[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Описание"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                v-model.trim="item.description"
                placeholder="Введите описание"
                :state="get(validation, 'description[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'description[0]')"
                class="text-danger"
              >
                {{ get(validation, 'description[0]') }}
              </small>
            </b-form-group>
          </b-col>
        </b-row>

        <offer-integrations
          :yandex-market-fbs="item.yandex_market_fbs"
          :yandex-market-dbs="item.yandex_market_dbs"
          :merlion-fresh-ymfbs="item.merlion_fresh_ymfbs"
          :merlion-ymdbs="item.merlion_ymdbs"
        />

        <b-row class="mt-1">
          <b-col>
            <b-form-group
              label="RichContent"
              label-for="website_content"
            >
              <mavon-editor
                ref="meditor"
                v-model="item.website_content"
                language="ru"
                :toolbars="{
                  bold: true,
                  italic: true,
                  header: true,
                  underline: true,
                  strikethrough: true,
                  mark: true,
                  superscript: true,
                  subscript: true,
                  quote: true,
                  ol: true,
                  ul: true,
                  link: true,
                  imagelink: true,
                  code: true,
                  table: true,
                  fullscreen: true,
                  readmodel: true,
                  htmlcode: true,
                  help: true,
                  /* 1.3.5 */
                  undo: true,
                  redo: true,
                  trash: true,
                  save: true,
                  /* 1.4.2 */
                  navigation: true,
                  /* 2.1.8 */
                  alignleft: false,
                  aligncenter: false,
                  alignright: false,
                  /* 2.2.1 */
                  subfield: true,
                  preview: true
                }"
                @imgAdd="imgAddForRichContent"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-1 text-right">
          <b-col>
            <b-button
              variant="primary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :disabled="addImageLoader"
              @click="updateItem"
            >
              <template v-if="!addImageLoader">
                Сохранить
              </template>
              <template v-else>
                <b-row
                  align-v="center"
                  align-h="center"
                  no-gutters
                >
                  <b-col cols="auto">
                    <b-spinner
                      small
                      class="mr-1"
                    />
                  </b-col>
                  <b-col cols="auto">
                    Loading...
                  </b-col>
                </b-row>
              </template>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <warehouse-remains
      v-if="item"
      :offer="item"
      @update-remain="runAfterUpdateRemain"
    />
    <product-info :product="product" />
  </b-overlay>
</template>

<script>
import {
  BAlert, BButton, BCard, BCol, BFormGroup, BFormInput, BLink, BOverlay, BRow, BTab, BTabs,
  BFormTextarea, BInputGroup, BCardHeader,
  BCardTitle, BTable, BFormCheckbox, BAvatar, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { get } from 'lodash'
import { getCurrentInstance, ref } from 'vue'
import { updateProductOffer as updateOffer, getProductOffer as getOffer } from '@/services/main-api/shop/product-offers'
import { getProduct as getProductApi } from '@/services/main-api/catalog/products/products'
import useCrudEdit from '@/composables/useCrudEdit'
import OfferIntegrations from '@/modules/offers/components/OfferIntegrations.vue'
import useIntegrations from '@/composables/useIntegrations'
import ProductInfo from '@/modules/offers/components/ProductInfo.vue'
import { addFile } from '@/services/main-api/medialibrary/media-files'
import WarehouseRemains from '../components/WarehouseRemains.vue'

export default {
  name: 'EditCompanyView',
  components: {
    BSpinner,
    OfferIntegrations,
    BAlert,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    vSelect,
    BTab,
    BTabs,
    BFormCheckbox,
    BFormTextarea,
    BInputGroup,
    BCardHeader,
    BCardTitle,
    BTable,
    BAvatar,
    WarehouseRemains,
    ProductInfo,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
  },
  setup(props) {
    const instance = getCurrentInstance().proxy
    const getItemError = ref(false)

    const product = ref({})
    const getProduct = async id => {
      try {
        const res = await getProductApi({ id, includes: 'images;files;seo' })
        product.value = res.data.data
      } catch (e) {
        product.value = {}
      }
    }

    const { items: integrations, getItems: getIntegrations } = useIntegrations()

    const getIntegrationsData = async () => {
      await getIntegrations()
      integrations.value.forEach(integration => {
        const type = integration.type.split('-').join('_')
        if (!instance.item[type].find(i => i.id === integration.id)) {
          instance.item[type].push({
            id: integration.id,
            enabled: false,
          })
        }
      })
    }

    const getItem = async () => {
      try {
        const res = await getOffer({ id: props.id, includes: 'product_warehouse_remains;product_warehouse_remains_with_logs' })
        const resP = res.data.data

        if (resP.website_content === null) {
          resP.website_content = ''
        }

        instance.item = resP

        await Promise.all([
          getProduct(res.data.data.product_id),
          getIntegrationsData(),
        ])
      } catch (e) {
        instance.item = {}
        instance.getItemError = true
      }
    }

    const updateEntity = async () => {
      await updateOffer({
        id: instance.item.id,
        price: instance.item.price,
        sale_price: instance.item.sale_price,
        purchase_price: instance.item.purchase_price,
        is_active: instance.item.is_active,
        description: instance.item.description,
        yandex_market_fbs: instance.item.yandex_market_fbs,
        yandex_market_dbs: instance.item.yandex_market_dbs,
        merlion_fresh_ymfbs: instance.item.merlion_fresh_ymfbs,
        merlion_ymdbs: instance.item.merlion_ymdbs,
        external_public_id: instance.item.external_public_id,
        website_content: instance.item.website_content,
      })
    }

    const {
      item,
      loading,
      validation,
      error_message,
      clearForm,
      updateItem,
    } = useCrudEdit({
      item: {
        id: null,
        project_id: null,
        product_id: null,
        price: null,
        sale_price: null,
        purchase_price: null,
        is_active: false,
        description: '',
        yandex_market_fbs: [],
        yandex_market_dbs: [],
        merlion_fresh_ymfbs: [],
        merlion_ymdbs: [],
        external_public_id: '',
        website_content: '',
      },
      validation: {
        rules: {
          price: 'required',
        },
        customErrorMessages: {
          'required.price': 'Укажите цену',
        },
      },
      getItem,
      updateEntity,
      getTextAfterUpdate: () => 'Предложение было изменено.',
      runAfterUpdate: async () => {
        await instance.$router.go(-1)
      },
    })

    const runAfterUpdateRemain = async () => {
      loading.value = true
      await getItem()
      loading.value = false
    }

    const addImageLoader = ref(false)

    const imgAddForRichContent = async (pos, file) => {
      addImageLoader.value = true

      try {
        const sizeMB = Math.ceil(file.size / 1024 / 1024)
        const res = await addFile({
          file,
        })
        const url = res.data?.data?.url
        await new Promise(r => setTimeout(() => r(), sizeMB * 500))
        instance.$refs.meditor.$img2Url(pos, url)
      } catch (e) {
        // ...
      } finally {
        addImageLoader.value = false
      }
    }

    return {
      item,
      loading,
      validation,
      error_message,
      clearForm,
      updateItem,

      get,
      product,
      getItemError,

      runAfterUpdateRemain,

      imgAddForRichContent,
      addImageLoader,
    }
  },
  mounted() {
    if (!this.$store.getters['workingMode/mode']) {
      this.$router.push({ name: 'dashboard' })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
