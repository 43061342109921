import axiosIns from '@/libs/axios'

export function getCategories({
  product_category, perPage, sortedBy, orderBy, page, search, searchFields, searchJoin,
}) {
  return axiosIns.get('/catalog/products/attributes/categories', {
    params: {
      product_category,
      perPage,
      sortedBy,
      orderBy,
      page,
      search,
      searchFields,
      searchJoin,
    },
  })
}

export function getCategory(id) {
  return axiosIns.get(`/catalog/products/attributes/categories/${id}`)
}

export function deleteCategory(id) {
  return axiosIns.delete(`/catalog/products/attributes/categories/${id}`)
}

export function createCategory({
  name,
  product_category_id,
  sort_order,
}) {
  return axiosIns.post('/catalog/products/attributes/categories', {
    name,
    product_category_id,
    sort_order,
  })
}

export function updateCategory({
  id,
  name,
  sort_order,
}) {
  return axiosIns.patch(`/catalog/products/attributes/categories/${id}`, {
    name,
    sort_order,
  })
}

export default {
  getCategories,
  getCategory,
  createCategory,
  updateCategory,
  deleteCategory,
}
